function getHint(id) {
  try {
    const hint = require(`./hints/${id}.json`);
    return {
      title: hint.title,
      description: hint.description,
    }
  } catch (error) {
    console.error(error);
    return {}
  }
}

export default getHint;