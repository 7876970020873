import getDomande from './get-domande';
import getArgomento from './get-argomento';

function pick(arr) {
  return arr[~~(Math.random() * arr.length)]
}

function pickDomanda(id_argomento) {
  console.log('Get domanda', id_argomento)
  const domande = getDomande(Number(id_argomento));
  const domanda = pick(domande);
  const argomento = getArgomento(domanda.id_chapter);
  console.log('Domanda', domanda, argomento);
  return {
    ...domanda,
    image: `000${domanda.image}`.substr(-3, 3),
    argomento: argomento.descrizione
  };
}

export default pickDomanda;